import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import loadjs from "loadjs";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, SplitLetters } from "react-gsap";
import { TweenMax, Bounce } from "gsap";

import ScrollAnimation from 'react-animate-on-scroll';

import { Title } from "./Title";
import LogoElement from "./LogoElement";
import Services from "./Services";
import Production from "./Production";
import Distribution from "./Distribution";
import Consumption from "./Consumption";
import Contact from "./Contact";
import Privacy from "./Privacy";
import LanguageSwitch from "./LanguageSwitch";

import { updateWindowWidth, updatePageScrollPosition } from "./state/actions";


function mapStateToProps(state) {
  return {
    language: state.language,
    fixHome: state.fixHome,
    windowWidth: state.windowWidth,
    pageScrollPos: state.pageScrollPos
  };
}


function mapDispatchToProps(dispatch) {
  return {
    updateWindowWidth: width => dispatch(updateWindowWidth(width)),
    updatePageScrollPosition: position => dispatch(updatePageScrollPosition(position))
  };
}


export class VisibleHome extends Component {
  mainFlag = false;
  aboutFlag = false;
  servicesFlag = false;
  contactFlag = false;

  constructor(props) {
    super(props);

    this.state = {
      scrollPosition: 0,
      trigger: 0,
      fixedElements: false,
      aboutOpen: false,
      aboutTesMarkup: <></>,
      aboutTrimbleBtnText: "",
      privacyOpen: false,
      mainPanelTop: 0,
      secondPanelTop: 0,
      servicesPanelTop: 0,
      contactPanelTop: 0,
      triggerLanguage: null
    };

    this.getScrollPosition = this.getScrollPosition.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.toggleAboutPanel = this.toggleAboutPanel.bind(this);
  }


  componentWillMount() {
    loadjs(process.env.REACT_APP_MIDNIGHT_JS_ROOT + "midnight.js", () => {
      window.$(".midnight_ctr").midnight();
    });
  }


  componentDidMount() {
    window.scrollTo(0, this.props.pageScrollPos);

    this.setContentByLanguage(this.props.language);

    this.updateDimensions();

    window.addEventListener("scroll", this.getScrollPosition);

    window.addEventListener("resize", this.updateDimensions);
  }


  getScrollPosition() {
    this.setState({
      scrollPosition: window.pageYOffset,
      trigger: window.innerHeight * 2
    });

    this.props.updatePageScrollPosition(window.pageYOffset);
  }

  toggleAboutPanel(event) {
    event.preventDefault();

    this.setState({
      aboutOpen: !this.state.aboutOpen
    })
  }


  togglePrivacyPanel(event) {
    event.preventDefault();

    this.setState({
      privacyOpen: !this.state.privacyOpen
    })
  }


  updateDimensions() {
    this.updatePanelState("home_ctr_second-panel", "secondPanelTop");
    this.updatePanelState("services_ctr", "servicesPanelTop");
    this.updatePanelState("contact_ctr", "contactPanelTop");

    this.props.updateWindowWidth(Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    ));
  }


  updatePanelState(panel, stateKey) {
    const panelDiv = document.querySelector("." + panel);

    panelDiv && this.setState({
      [stateKey]: panelDiv.offsetTop - 200
    });
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.language != prevProps.language) {
      this.setContentByLanguage(this.props.language);
    }

    if(!this.mainFlag) {
      if(window.pageYOffset >= 0 && window.pageYOffset < this.state.secondPanelTop) {
        TweenMax.to([".title_ctr", ".lang-switch_ctr", ".home_txt_lang"], 1, {
          css: {
            opacity: 0
          }
        });
        TweenMax.to(".title_svg_about", 1, {
          y: 40,
          ease: Bounce.easeOut
        });

        if(this.props.windowWidth <= 800) {
          TweenMax.to(".home_ctr_fixed-tes-name", 1, {
            css: {
              opacity: 1
            }
          });
        }
      }

      this.mainFlag = true;
      this.aboutFlag = false;
      this.servicesFlag = false;
      this.contactFlag = false;
    }


    if(!this.aboutFlag) {
      if(window.pageYOffset >= this.state.secondPanelTop && window.pageYOffset < this.state.servicesPanelTop) {
        TweenMax.to([".title_ctr", ".lang-switch_ctr", ".home_txt_lang"], 1, {
          css: {
            opacity: 1
          }
        });
        TweenMax.to(".title_svg_about", 1, {
          y: -40,
          ease: Bounce.easeOut
        });
        TweenMax.to(".title_svg_services", 1, {
          y: 40,
          ease: Bounce.easeOut
        });

        if(this.props.windowWidth <= 800) {
          TweenMax.to(".home_ctr_fixed-tes-name", 1, {
            css: {
              opacity: 0
            }
          });
        }

        this.mainFlag = false;
        this.aboutFlag = true;
        this.servicesFlag = false;
        this.contactFlag = false;
      }
    }

    if(!this.servicesFlag) {
      if(window.pageYOffset >= this.state.servicesPanelTop && window.pageYOffset < this.state.contactPanelTop) {
        TweenMax.to([".title_ctr", ".lang-switch_ctr", ".home_txt_lang"], 1, {
          css: {
            opacity: 1
          }
        });
        TweenMax.to(".title_svg_about", 1, {
          y: -80,
          ease: Bounce.easeOut
        });
        TweenMax.to(".title_svg_services", 1, {
          y: -40,
          ease: Bounce.easeOut
        });
        TweenMax.to(".title_svg_contact", 2, {
          y: 40,
          ease: Bounce.easeOut
        });

        if(this.props.windowWidth <= 800) {
          TweenMax.to(".home_ctr_fixed-tes-name", 1, {
            css: {
              opacity: 0
            }
          });
        }

        this.mainFlag = false;
        this.aboutFlag = false;
        this.servicesFlag = true;
        this.contactFlag = false;
      }
    }

    if(!this.contactFlag) {
      if(window.pageYOffset >= this.state.contactPanelTop) {
        TweenMax.to([".title_ctr", ".lang-switch_ctr", ".home_txt_lang"], 1, {
          css: {
            opacity: 1
          }
        });
        TweenMax.to(".title_svg_services", 1, {
          y: -80,
          ease: Bounce.easeOut
        });
        TweenMax.to(".title_svg_contact", 1, {
          y: -40,
          ease: Bounce.easeOut
        });

        if(this.props.windowWidth <= 800) {
          TweenMax.to(".home_ctr_fixed-tes-name", 1, {
            css: {
              opacity: 0
            }
          });
        }

        this.mainFlag = false;
        this.aboutFlag = false;
        this.servicesFlag = false;
        this.contactFlag = true;
      }
    }


    if(window.pageYOffset > (this.state.trigger + 0) && !this.state.fixedElements) {
      this.setState({
        fixedElements: true
      });

      return;
    }

    if(window.pageYOffset <= (this.state.trigger + 0)  && this.state.fixedElements) {
      this.setState({
        fixedElements: false
      });
    }

    document.body.style.overflow = this.props.fixHome ? "hidden" : "visible";
    document.body.style.position = this.props.fixHome ? "absolute" : "relative";

  }


  componentWillUnmount() {
    window.removeEventListener("scroll", this.getScrollPosition);
    window.removeEventListener("resize", this.updatePanelTops);
  }


  setContentByLanguage(language) {
    this.setState({
      aboutTesMarkup: this.setAboutTesMarkupByLanguage(language),
      aboutTrimbleBtnText: language == "lt" ? "Sužinoti daugiau" : "Learn more"
    });
  }


  switchLanguageOnClick(language) {
    this.setState({
      triggerLanguage: language
    });
  }


  nullifyTriggerLanguage(flag) {
    if(flag) {
      this.setState({
        triggerLanguage: null
      });
    }
  }


  setAboutTesMarkupByLanguage(language) {
    if(language == "lt") {
      return (
        <>
          <p>UAB „TEISINGI ENERGETIKOS SPRENDIMAI“ (TES) – Lietuvos rinkoje vertinamas ekspertas, teikiantis kompleksines konsultacijas su energijos gamyba, vartojimu, tiekimu bei šių sričių modernizavimu susijusiems projektams. Jau beveik dešimtmetį TES specialistų kompetencija pasitiki ne tik didžiausi energijos gamintojai ir tiekėjai Lietuvoje, bet ir įvairios pramonės įmonės, užsienio kapitalo bendrovės, nekilnojamojo turto vystytojai, valstybinio sektoriaus įstaigos bei įmonės.</p>



          <p>Nuo 2019 metų TES tapo oficialiu „TRIMBLE NIS“ programinės įrangos diegimo partneriu Lietuvos CŠT bei vandentvarkos sektoriuose.</p>

          <p>2021-aisiais TES tapo oficialiu „AGAINITY“ agentu Lietuvos rinkai, kurio tikslas - dalintis patirtimi ir siūlomais produktais su Lietuvos energetikos sektoriaus bendrovėmis.</p>

          <p>TES taip pat yra ir „FUSEBOX“ partneris Lietuvos rinkai. Tai leidžia pasiūlyti įvairių sprendimų, kaip organizacijoms sumažinti išlaidas elektros energijai bei CO<sub>2</sub> emisijas, dalyvaujant elektros tinklo balansavime.</p>
        </>
      );
    } else {
      return (
        <>

            <p>TEISINGI ENERGETIKOS SPRENDIMAI (TES) offers a combination of innovative engineering and legal consulting services to respond to the growing need for in-depth expert advice within the energy sector in Lithuania. For almost a decade TES has provided clients with optimal solutions at the forefront in areas of energy production, distribution and consumption. Our clients are energy production and distribution utilities, government agencies, policy makers and regulators, investors in the energy sector, owners of industrial enterprises as well as developers of commercial and residential real estate projects.</p>



            <p>In 2019 a contract signed with the Finnish company Trimble Solutions Oy gave TES the right to be the official partner in Lithuania for installing and maintaining Trimble NIS technology.</p>

          <p>The year 2021 marks TES becoming an official agent representative of the AGAINITY system applications in the Lithuanian market.</p>

          <p>TES is also partnering with FUSEBOX and can now offer various solutions for Lithuanian companies in enabling better integration of renewables and the reduction of energy expenses as well as that of CO<sub>2</sub>.</p>
        </>
      );
    }
  }


  render() {
    const tesT = (
      <img src="assets/tes-t.svg" alt="T" />
    );

    const tesE = (
      <img src="assets/tes-e.svg" alt="E" />
    );

    const tesS = (
      <img src="assets/tes-s.svg" alt="S" />
    );

    const tesName = (
      <>
        <div>Teisingi</div>
        <div>Energetikos</div>
        <div>Sprendimai</div>
      </>
    );

    let contentTesT = (
      <LogoElement fromTop="40%" tweenTop="21px" tweenLeft="20px" classes="home_ctr_tes-t" content={tesT} style={{ opacity: 0 }} />
    );
    let contentTesE = (
      <LogoElement fromTop="40%" tweenTop="21px" tweenRight="20px" classes="home_ctr_tes-e" content={tesE} style={{ opacity: 0 }} />
    );
    let contentTesS = (
      <LogoElement fromTop="40%" tweenRight="20px" tweenBottom="17px" classes="home_ctr_tes-s" content={tesS} style={{ opacity: 0 }} />
    );
    let contentTesName = (
      <></>
    );

    if(!this.state.fixedElements) {
      contentTesT = (
        <LogoElement fromTop="40%" tweenTop="21px" tweenLeft="20px" classes="home_ctr_tes-t" content={tesT} style={{ opacity: 1 }} />
      );
      contentTesE = (
        <LogoElement fromTop="40%" tweenTop="21px" tweenRight="20px" classes="home_ctr_tes-e" content={tesE} style={{ opacity: 1 }} />
      );
      contentTesS = (
        <LogoElement fromTop="40%" tweenRight="20px" tweenBottom="17px" classes="home_ctr_tes-s" content={tesS} style={{ opacity: 1 }} />
      );
      contentTesName = (
        <LogoElement fromTop="70%" tweenBottom="20px" tweenLeft="20px" width="100px" classes="home_ctr_tes-full-text" content={tesName} style={{ opacity: 1 }} />
      );
    }

    return (
      <div className="home_ctr">
        <div className="home_ctr_fixed-tes-t midnight_ctr" style={ { opacity: this.state.fixedElements ? 1 : 0 } }>
          <svg x="0px" y="0px" viewBox="0 0 250 300">
            <path className="st0" d="M250.2,31.3H0.2V6h250V31.3z M114.4,294.5H87.5V76.7H0.2V51.5h114.2V294.5z M250.2,76.7h-87.3v217.8H136v-243h114.2V76.7z" />
          </svg>
        </div>

        <div className="home_ctr_fixed-tes-e midnight_ctr" style={ { opacity: this.state.fixedElements ? 1 : 0 } }>
          <svg x="0px" y="0px" viewBox="0 0 250 300">
            <path className="st0" d="M225.5,269.2v25.2H23.3V6h202.2v25.2H50.2v238H225.5z M98.7,113.7h121.6V139H71.8V51.5h153.7v25.2H98.7V113.7zM220.4,159.2v25.2H98.7v39.4h126.8v25.2H71.8v-89.9H220.4z" />
          </svg>
        </div>

        <div className="home_ctr_fixed-tes-s midnight_ctr" style={ { opacity: this.state.fixedElements ? 1 : 0 } }>
          <svg x="0px" y="0px" viewBox="0 0 250 300">
            <path className="st0" d="M190.3,97.1l-26.9,2.9c-1-16.1-14.9-29.1-40.5-29.1c-20.3,0-31.3,8.4-31.3,20.2c0,8.9,8,15.4,24.1,19.2
l36.4,9.1c32.6,7.9,55.4,18.3,68.5,31s19.8,29.8,19.8,51.2c0,31.7-13.1,57.9-34.4,74c-20.8,15.6-48.2,24.5-78,24.5
c-67.7,0-107.8-34.1-119.3-90.1L35,207c11,44.2,42.6,68,92.9,68c24.4,0,44.9-6,61.1-18c16.2-12.3,24.4-29.1,24.4-51
c0-30-21.6-50.5-64.7-61.3l-35.9-8.9c-13.3-3.6-22.1-6.5-32.3-13c-10.3-6.7-15.9-16.8-15.9-30.3c0-12.3,5.1-23.1,15.7-32.5
c10.5-9.6,25.4-14.4,44.7-14.4C160,45.7,184.4,63.5,190.3,97.1z M201.6,25.7c20.8,17.1,33.1,39.4,37,67.1l-26.7,2.4
c-5.9-45.2-39-69.7-87-69.7c-24.4,0-44.1,6.3-59.3,19C50.4,57,43,73.6,43,94c0,16.1,6.2,29.3,18.7,39.7c12.6,10.3,28.7,18,48.5,23.1
l35.2,8.4c31.1,7.5,46.4,21.4,46.4,41.6c0,14.7-5.9,26.2-17.7,35.1c-11.5,8.7-26.9,13-46.2,13c-37.2,0-63.1-19.2-71.1-51l26.9-3.4
c3.8,16.3,19.2,29.1,46.2,29.1c23.4,0,34.9-8.4,34.9-20c0-11.3-6.9-15.9-25.1-20l-33.9-8.2C48.4,167.8,16,140.9,16,94
c0-27.6,10.3-50.2,30.8-67.5c20.5-17.5,46.4-26.2,78-26.2C155.4,0.3,180.8,8.7,201.6,25.7z" />
          </svg>
        </div>

        <div className="home_ctr_fixed-tes-name midnight_ctr" ref="midnight" style={ { visibility: this.state.fixedElements ? "visible" : "hidden" } }>
          Teisingi<br />
          Energetikos<br />
          Sprendimai
        </div>

        <Title language={this.props.language} />

        <LanguageSwitch triggerLanguage={this.state.triggerLanguage} callback={this.nullifyTriggerLanguage.bind(this)} />

        <div className="home_txt_lang _mod_lt midnight_ctr" onClick={this.switchLanguageOnClick.bind(this, "lt")}>LT</div>
        <div className="home_txt_lang _mod_en midnight_ctr" onClick={this.switchLanguageOnClick.bind(this, "en")}>EN</div>

        <div className="home_ctr_top-panel" data-midnight="red">
          {contentTesT}
          {contentTesE}
          {contentTesS}
          {contentTesName}

          <Controller>
            <Scene triggerElement=".home_ctr_top-panel" triggerHook="onLeave" offset={1000} duration={1000}>
              {(progress) => (
                <Tween to={{ css: { width: this.props.windowWidth > 800 ? "50%" : "80%", opacity: 1 } }} ease="Strong.easeOut" totalProgress={progress} paused>
                  <img className="home_ctr_gradient" src="assets/tes-gradient.svg" />
                </Tween>
              )}
            </Scene>
          </Controller>
        </div>



            <div id="secondPanel" className="home_ctr_second-panel" data-midnight="white">

          <div className="home_ctr_info-text">
            {this.state.aboutTesMarkup}
          </div>


            <div className="home_ctr_logo-ctrl">
              <Link to="/about" className="home_lnk_about-more">
                {this.state.aboutTrimbleBtnText}
              </Link>

              <br />

              <a href="https://www.trimble.com" target="_blank" rel="noopener noreferrer" title="Trimble">
                <img src="assets/trimble-grey.svg" alt="Trimble" />
              </a>

              <a href="https://againity.com" target="_blank" rel="noopener noreferrer" title="Againity">
                <img className="home_img_againity" src="assets/logo-againity.svg" alt="Againity" />
              </a>

              <a href="https://fusebox.energy" target="_blank" rel="noopener noreferrer" title="Fusebox">
                <img src="assets/logo-fusebox.svg" alt="Fusebox" />
              </a>
            </div>

        </div>






        <Services />

        <Contact />
      </div>
    );
  }
}

const Home = connect(mapStateToProps, mapDispatchToProps)(VisibleHome);
export default Home;

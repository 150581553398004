import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";


function mapStateToProps(state) {
  return {
    language: state.language
  };
}


export class VisibleAbout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      markup: <></>,
      closeText: ""
    };
  }


  componentDidMount() {
    window.scroll(0, 0);

    this.setState({
      markup: this.setTextByLanguage(this.props.language),
      closeText: this.setCloseTextByLanguage(this.props.language)
    });
  }


  setTextByLanguage(language) {
    let markup;

    if(language == "lt") {
      markup = (
        <>
          <p className="about_txt_title">„Trimble NIS“ programinė įranga CŠT ir vandentvarkos įmonių sistemų modernizavimui</p>

          <p>Skandinavų kompanijos „Trimble Solutions Oy“ sukurta programinė įranga „Trimble NIS“ yra plačiai paplitusi Suomijoje ir Švedijoje, kur ją įdiegę daugiau nei 60 klientų vandentvarkos srityje ir 50 centralizuoto šilumos tiekimo (CŠT) sektoriuje. Jau daugiau nei 30 metų šis technologinis įrankis pasitelkiamas sėkmingai organizuoti tinklų priežiūros ir eksploatacijos procesus.</p>

          <p>2019 metų pradžioje pasirašyta sutartis suteikia UAB „Teisingi energetikos sprendimai“ (TES) teisę tarpininkauti Lietuvoje diegiant „Trimble NIS“ technologiją bei užtikrinant jos palaikymą.</p>

          <p>„Atsitiktinai, vizito pas potencialų klientą Lietuvoje metu susipažinę su patyrusiais TES ekspertais, supratome, kad mūsų produktą diegti naujoje rinkoje, tokioje kaip Lietuva, reikia patikimo partnerio. Sistemą būtina adaptuoti lokaliems poreikiams, o nuolatinė pagalba bei konsultacijos bus reikalingos ne tik anglų, bet ir gimtąja – lietuvių – kalba. Visas šias funkcijas patikime TES specialistams“, – teigė Antti Nevas, „Trimble Solutions Oy“ segmento direktorius.</p>

          <p>Klaipėda tapo pirmuoju Lietuvos miestu, pasiryžusiu diegti pažangiausias technologijas miesto vandentvarkos infrastruktūros valdymui. AB „Klaipėdos vanduo” pasirinko „Trimble NIS“ technologinį sprendimą, kuris leis jai palengvinti tinklų eksploatacijos ir aptarnavimo procesus, tinkamai organizuoti specialistų darbą objektuose, aptarnaujant ar likviduojant avarines bei nenumatytas situacijas. Bendrovei taip pat reikia atlikti patikrinamuosius hidraulinius skaičiavimus, kurie užtikrintų, kad klojami reikiamo skersmens vamzdynai vandentvarkos rekonstrukcijos arba plėtros projektuose. Sistema sėkmingai įdiegta ir naudojama bendrovėje nuo 2021 sausio.</p>

          <p>Daugiau informacijos apie „Trimble“</p>

          <p>
            <a className="_mod_underline" href="https://upa.trimble.com/en/products/end-customer-communications?page=1&category=356" title="Trimble" target="_blank" rel="noopener noreferrer">www.trimble.com</a>.
          </p>
        </>
      );
    } else {
      markup = (
        <>
          <p className="about_txt_title">Trimble NIS Software for Modernisation of Water and District Heating Networks</p>

          <p>Trimble NIS software has been successfully used in Finland and Sweden for almost 30 years where it has been installed in more than 60 water and 50 district heating utilities. In 2019 Teisingi Energetikos Sprendimai signed a contract with the Finnish company Trimble Solutions Oy and became the official partner in Lithuania for installing and maintaining Trimble NIS technology.</p>

          <p>"We were looking for a local partner and accidentally found one during our visit to a potential customer in Lithuania. We believe that we have an excellent network information system for water and district heating utilities and all we were lacking was a reliable energy consultant that knows the local market”, says Antti Nevas, Segment Director at Trimble Solutions Oy.</p>

          <p>Klaipėda became the first city in Lithuania determined to implement this most advanced technology for the administering of an urban water management infrastructure. For quite some time AB Klaipėdos Vanduo was looking for an effective technological solution that would facilitate the operation and maintenance of its networks, properly organize the work of specialists in the facilities, and servicing or the elimination of emergency and unforeseen situations. The company also needs to carry out reliable hydraulic calculations to ensure that pipelines with the required diameter are laid in water management reconstructions or development projects. Trimble NIS software has been installed and is operating to full capacity since January 2021.</p>

          <p>
            More information: <a className="_mod_underline" href="https://upa.trimble.com/en/products/end-customer-communications?page=1&category=356" title="Trimble" target="_blank" rel="noopener noreferrer">www.trimble.com</a>.
          </p>
        </>
      );
    }

    return markup;
  }


  setCloseTextByLanguage(language) {
    return language == "lt" ? "Uždaryti" : "Close";
  }


  render() {
    return (
      <div className="about_ctr">
        <div className="about_ctr_content">
          <div className="about_ctr_close">
            <Link to="/">
              <button type="button" className="_mod_black">{this.state.closeText} x</button>
            </Link>
          </div>

          <div className="about_ctr_text">
            <a href="https://www.trimble.com" title="Trimble" target="_blank" rel="noopener noreferrer">
              <img src="assets/trimble-blue.svg" alt="Trimble" />
            </a>
            <div>
              {this.state.markup}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const About = connect(mapStateToProps, null)(VisibleAbout);
export default About;

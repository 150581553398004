import { ofType } from "redux-observable";
import { tap, ignoreElements } from "rxjs/operators";

import { SET_LANGUAGE } from "./actions";


export const languageEpic = (action$, state$) => action$.pipe(
  ofType(SET_LANGUAGE),
  tap(() => {
    window.localStorage.setItem("be_tes_lang", state$.value.language);
  }),
  ignoreElements()
);

import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { Content } from "./Content";

import "./App.css";

/**
 * TES BE Web Application
 *
 * APP COMPONENT
 *
 * @author Eric L., Birute M.
 * @copyright 2019 BE Studio
 * @see https://itsbe.studio
 */

/**
 * Represents root app component.
 *
 * @returns {*}
 * @constructor
 */
function App() {
  return (
    <Router basename={process.env.REACT_APP_BASE}>
      <div className="App">
        <Content />
      </div>
    </Router>
  );
}
export default App;

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Draggable from "react-draggable";

import { setLanguage } from "./state/actions";


function mapStateToProps(state) {
  return {
    language: state.language
  };
}


function mapDispatchToProps(dispatch) {
  return {
    setLanguage: language => dispatch(setLanguage(language))
  };
}


export class VisibleLanguageSwitch extends Component {
  TOP_POSITION = 50;
  BOTTOM_POSITION = 160;


  constructor(props) {
    super(props);

    this.state = {
      thumbPosition: this.TOP_POSITION
    };

    this.handleStart = this.handleStart.bind(this);
    this.handleStop = this.handleStop.bind(this);
  }


  componentDidMount () {
    this.setLanguage(this.props.language);
    this.setSwitch(this.props.language);
  }


  componentWillReceiveProps (nextProps, nextContext) {
    if(nextProps.triggerLanguage != this.props.triggerLanguage && nextProps.triggerLanguage) {
      this.setState({
        thumbPosition: nextProps.triggerLanguage == "lt" ? this.TOP_POSITION : this.BOTTOM_POSITION
      });

      this.setLanguage(nextProps.triggerLanguage);

      this.props.callback(true);
    }
  }


  handleStart(event) {
    event.preventDefault();
  }


  handleStop(event, data) {
    if(data.y > this.TOP_POSITION && this.props.language == "lt") {
      this.setState({
        thumbPosition: this.BOTTOM_POSITION
      });
      this.setLanguage("en");

      return;
    }

    if(data.y < this.BOTTOM_POSITION && this.props.language == "en") {
      this.setState({
        thumbPosition: this.TOP_POSITION,
      });
      this.setLanguage("lt");
    }
  }


  setLanguage(language) {
    this.props.setLanguage(language);
  }


  setSwitch(language) {
    const position = this.props.language == "lt" ? this.TOP_POSITION : this.BOTTOM_POSITION;

    this.setState({
      thumbPosition: position
    });
  }


  render() {
    const style = {
      backgroundImage: "url('assets/language-switch.svg')"
    };

    return (
      <div className="lang-switch_ctr">
        <div className="lang-switch_ctr_content">
          <Draggable
            axis="y"
            handle=".lang-switch_ctr_thumb"
            defaultPosition={{
              x: 0,
              y: this.state.thumbPosition
            }}
            position={{
              x: 0,
              y: this.state.thumbPosition
            }}
            bounds={{
              top: this.TOP_POSITION,
              bottom: this.BOTTOM_POSITION
            }}
            grid={[1, 1]}
            scale={1}
            onStart={this.handleStart}
            onStop={this.handleStop}
          >
            <div>
              <div className="lang-switch_ctr_thumb" id="thumb" style={style}></div>
            </div>
          </Draggable>


          <div className="lang-switch_ctr_slide">
            <img src="assets/language-slide.svg" alt="" />
          </div>

        </div>
      </div>
    );
  }
}

const LanguageSwitch = connect(mapStateToProps, mapDispatchToProps)(VisibleLanguageSwitch);

LanguageSwitch.propTypes = {
  triggerLanguage: PropTypes.string
};

export default LanguageSwitch;

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Controller, Scene } from "react-scrollmagic";
import { Tween } from "react-gsap";


function mapStateToProps(state) {
  return {
    windowWidth: state.windowWidth
  };
}

/**
 * TES BE Web Application
 *
 * LOGO ELEMENT COMPONENT
 *
 * @author Eric L., Birute M.
 * @copyright 2019 BE Studio
 * @see https://itsbe.studio
 */

/**
 * Represents component for one element of the brand logo.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export class VisibleLogoElement extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    const
      defaultFromTop = "auto",
      defaultTop = "auto",
      defaultRight = "auto",
      defaultBottom = "auto",
      defaultLeft = "auto",
      defaultWidth = "4%",
      defaultMobileWidth = "10%";

      return (
        <Controller>
        <Scene triggerElement=".home_ctr_top-panel" triggerHook="onLeave" duration={2000}>
          {(progress) => (
            <Tween
              from={{
                css: {
                  top: this.props.fromTop || defaultTop
                }
              }}
              to={{
                css: {
                  top: this.props.tweenTop || defaultTop,
                  right: this.props.tweenRight || defaultRight,
                  bottom: this.props.tweenBottom || defaultBottom,
                  left: this.props.tweenLeft || defaultLeft,
                  fontSize: "12px",
                  width: this.props.windowWidth > 800 ? this.props.width || defaultWidth : this.props.mobileWidth || defaultMobileWidth,
                  transform: "none" }
              }}
              ease="Strong.easeOut"
              totalProgress={progress}
              paused
            >
              <div className={"home_ctr_tes " + this.props.classes}>
                {this.props.content}
              </div>
            </Tween>
          )}
        </Scene>
      </Controller>
    );
  }
}


const LogoElement = connect(mapStateToProps, null)(VisibleLogoElement);

LogoElement.propTypes = {
  /**
   * Topmost position from which animation occurs.
   */
  fromTop: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  /**
   * Topmost position to animate to.
   */
  tweenTop: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  /**
   * Rightmost position to animate to.
   */
  tweenRight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  /**
   * Bottom-most position to animate to.
   */
  tweenBottom: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  /**
   * Leftmost position to animate to.
   */
  tweenLeft: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  /**
   * Width of element to animate to.
   */
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  /**
   * Width of element to animate to on mobile platforms.
   */
  mobileWidth: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  /**
   * Additional CSS classes to apply to element.
   */
  classes: PropTypes.string.isRequired,
  /**
   * Content within element tags.
   */
  content: PropTypes.any.isRequired
};

export default LogoElement;

import React, { Component } from "react";
import { Route } from "react-router-dom";

import Home from "./Home";
import About from "./About";
import Production from "./Production";
import Distribution from "./Distribution";
import Consumption from "./Consumption";
import Privacy from "./Privacy";

/**
 * TES BE Web Application
 *
 * CONTENT COMPONENT
 *
 * @author Eric L., Birute M.
 * @copyright 2019 BE Studio
 * @see https://itsbe.studio
 */

/**
 * Provides container for content.
 */
export class Content extends Component {
  render() {
    return (
      <div className="content_ctr">
        <Route exact path="/" component={Home} />
        <Route path="/about" component={About} />
        <Route path="/production" component={Production} />
        <Route path="/distribution" component={Distribution} />
        <Route path="/consumption" component={Consumption} />
        <Route path="/privacy" component={Privacy} />
      </div>
    );
  }
}

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

import {
  toggleServicesBackgroundRed,
  stopServiceIconAnims
} from "./state/actions";

const ServiceTypes = {
  GAMYBA: 0,
  TIEKIMAS: 1,
  VARTOJIMAS: 2
};


function mapStateToProps(state) {
  return {
    language: state.language,
    stopServiceIconAnimsProp: state.stopServiceIconAnimsProp
  };
}


function mapDispatchToProps(dispatch) {
  return {
    toggleServicesBackgroundRed: flag => dispatch(toggleServicesBackgroundRed(flag)),
    stopServiceIconAnims: flag => dispatch(stopServiceIconAnims(flag))
  };
}

export class VisibleServices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gamybaMarkup: <></>,
      tiekimasMarkup: <></>,
      vartokimasMarkup: <></>,
      bgStyle: {
        backgroundColor: "#f2f2f2"
      },
      gamybaIcon: <></>,
      tiekimasIcon: <></>,
      vartojimasIcon: <></>,
      serviceIcons: <></>
    };

    this.onHover = this.onHover.bind(this);
    this.toggleServicesBgRed = this.toggleServicesBgRed.bind(this);
    this.storeScrollPosition = this.storeScrollPosition.bind(this);
    this.generateIconsMarkup = this.generateIconsMarkup.bind(this);
    this.setIconsAnimState = this.setIconsAnimState.bind(this);
  }


  componentDidMount() {
    this.setTextByLanguage(this.props.language);
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.language != prevProps.language) {
      this.setTextByLanguage(this.props.language);
    }
  }

  toggleServicesBgRed(flag) {
    this.props.toggleServicesBackgroundRed(flag);
  }


  onHover(event, serviceType) {
    const style = {
      backgroundColor: "#f03"
    };

    switch(serviceType) {
    case ServiceTypes.GAMYBA:
      event.currentTarget.src = "assets/gamyba-production-gradient.svg";
      break;
    case ServiceTypes.TIEKIMAS:
      event.currentTarget.src = "assets/tiekimas-distribution-gradient.svg";
      break;
    case ServiceTypes.VARTOJIMAS:
      event.currentTarget.src = "assets/vartojimas-consumption-gradient.svg";
      break;
    default:
      break;
    }

    this.setState({
      bgStyle: style
    });
  }


  onMouseOut(event, serviceType) {
    const style = {
      backgroundColor: "#f2f2f2"
    };

    switch(serviceType) {
    case ServiceTypes.GAMYBA:
      event.currentTarget.src = "assets/gamyba-production-red.svg";
      break;
    case ServiceTypes.TIEKIMAS:
      event.currentTarget.src = "assets/tiekimas-distribution-red.svg";
      break;
    case ServiceTypes.VARTOJIMAS:
      event.currentTarget.src = "assets/vartojimas-consumption-red.svg";
      break;
    default:
      break;
    }

    this.setState({
      bgStyle: style
    });
  }


  setTextByLanguage(language) {
    this.setState({
      gamybaMarkup: <>{language == "lt" ? "Gamyba" : "Production"}</>,
      tiekimasMarkup: <>{language == "lt" ? "Tiekimas" : "Distribution"}</>,
      vartojimasMarkup: <>{language == "lt" ? "Vartojimas" : "Consumption"}</>
    }, () => this.generateIconsMarkup());
  }


  generateIconsMarkup() {
    this.setState({
      gamybaIcon: (
        <>
        <Link to="/production" onClick={this.storeScrollPosition}>
          <img src="assets/gamyba-production-red.svg" onMouseOver={event => this.onHover(event, ServiceTypes.GAMYBA)} onMouseOut={event => this.onMouseOut(event, ServiceTypes.GAMYBA)} alt="Gamyba" />
        </Link>

        <div>
          {this.state.gamybaMarkup}
        </div>
      </>
      ),
      tiekimasIcon: (
        <>
        <Link to="/distribution" onClick={this.storeScrollPosition}>
          <img src="assets/tiekimas-distribution-red.svg" onMouseOver={event => this.onHover(event, ServiceTypes.TIEKIMAS)} onMouseOut={event => this.onMouseOut(event, ServiceTypes.TIEKIMAS)} alt="Teikimas" />
        </Link>

        <div>
          {this.state.tiekimasMarkup}
        </div>
      </>
      ),
      vartojimasIcon: (
        <>
        <Link to="/consumption" onClick={this.storeScrollPosition}>
          <img src="assets/vartojimas-consumption-red.svg" onMouseOver={event => this.onHover(event, ServiceTypes.VARTOJIMAS)} onMouseOut={event => this.onMouseOut(event, ServiceTypes.VARTOJIMAS)} alt="Vartojimas" />
        </Link>

        <div>
          {this.state.vartojimasMarkup}
        </div>
      </>
      )
    }, () => this.setIconsAnimState());
  }


  setIconsAnimState() {
    if(!this.props.stopServiceIconAnimsProp) {
      this.setState({
        serviceIcons: (
          <>
          <div>
              <ScrollAnimation animateIn="bounceInUp">
                {this.state.gamybaIcon}
              </ScrollAnimation>
            </div>

            <div>
              <ScrollAnimation animateIn="bounceInUp" delay={100}>
                {this.state.tiekimasIcon}
              </ScrollAnimation>
            </div>

            <div>
              <ScrollAnimation animateIn="bounceInUp" animateOut="fadeInDown" delay={200}>
                {this.state.vartojimasIcon}
              </ScrollAnimation>
            </div>
        </>
        )
      });

      this.props.stopServiceIconAnims(true);
    } else {
      this.setState({
        serviceIcons: (
          <>
        <div>

          <div>{this.state.gamybaIcon}</div>

            </div>

            <div>

              <div>{this.state.tiekimasIcon}</div>
            </div>

            <div>
              <div>{this.state.vartojimasIcon}</div>
            </div>
      </>
        )
      });
    }
  }


  storeScrollPosition() {
    localStorage.setItem("be_tes_scroll-pos", window.pageYOffset);
  }


  render() {
    return (
      <div className="services_ctr" data-midnight="red" style={this.state.bgStyle}>
        <div>
          <div className="services_ctr_content">
            {this.state.serviceIcons}
          </div>

          <div className="services_ctr_mobile-content">
            <div>
              <div>
                {this.state.gamybaIcon}
              </div>

              <div>
                {this.state.tiekimasIcon}
              </div>

              <div>
                {this.state.vartojimasIcon}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const Services = connect(mapStateToProps, mapDispatchToProps)(VisibleServices);
export default Services;

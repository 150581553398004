import React, { Component } from "react";

import { Link } from 'react-router-dom'
import { connect } from "react-redux";
import { TimelineMax, TweenMax, Linear } from "gsap";

import LanguageSwitch from "./LanguageSwitch";

import { toggleProduction } from "./state/actions";


function mapStateToProps(state) {
  return {
    language: state.language,
    productionDisplay: state.productionDisplay
  };
}


export class VisibleProduction extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: <></>,
      closeBtnText: "",
      circleIndex: 0
    };
  }


  componentDidMount() {
    window.scroll(0, 0);
    this.setTextByLanguage(this.props.language);

    this.updateContainerHeight();

    window.addEventListener("resize", this.updateContainerHeight);


    TweenMax.to([".production_svg_text", ".production_svg_ring-inner"], 4, {
      css: {
        svgOrigin: "400 250",
        rotation: 360
      },
      repeat: -1,
      ease: Linear.easeNone
    });

    TweenMax.to(".production_svg_ring-outer", 3, {
      css: {
        svgOrigin: "400 250",
        rotation: -360
      },
      repeat: -1,
      ease: Linear.easeNone
    });

    const plusTimeline = new TimelineMax({
      repeat: -1
    });
    plusTimeline.to(".production_svg_plus", 0.5, {
      scale: 2,
      transformOrigin: "center",
        ease: Linear.easeNone
    })
      .to(".production_svg_plus", 0.5, {
        scale: 1,
        transformOrigin: "center",
        ease: Linear.easeNone
      });

    const minusTimeline = new TimelineMax({
      repeat: -1
    });
    minusTimeline.to(".production_svg_minus", 0.5, {
        scale: 2,
        transformOrigin: "center",
        ease: Linear.easeNone
      })
      .to(".production_svg_minus", 0.5, {
        scale: 1,
        transformOrigin: "center",
        ease: Linear.easeNone
      });
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.language != prevProps.language) {
      this.setTextByLanguage(this.props.language);
    }
  }


  setTextByLanguage(language) {
    const ltMarkup = (
      <>
        <ul>
          <li>Techninės-finansinės-ekonominės-aplinkosauginės analizės, padedančios pasirinkti efektyviausią energijos apsirūpinimo būdą</li>
          <li>Esamo energijos gamybos ūkio efektyvumo didinimo sprendimų techninis-finansinis vertinimas</li>
          <li>Dokumentų, reikalingų bendrovės dalyvavimo VIAP susigrąžinimo schemoje, parengimas</li>
          <li>Projektų finansavimo ES/Klimato kaitos paramos lėšų dokumentų parengimas (investicijų projektai,„Cost-to-Benefit“ analizės ir kt.)</li>
          <li>Pirkimų dokumentų, techninių specifikacijų rastiems sprendimams parengimas, pirkimų priežiūra, ekspertinės pasiūlymų vertinimo paslaugos</li>
          <li>Statinio statybos techninė priežiūra</li>
          <li>Konsultavimas energijos gamybai reikalingų dokumentų ir leidimų, tarifų, prisijungimo prie tinklų klausimais, užsakovo atstovavimas, konsultatvimas reguliavimo klausimais</li>
          <li>Konsultacijos energijos gamybą vykdančios įmonės veiklos reguliavimo klausimais</li>
          <li>Investicijų derinimas VERT</li>
        </ul>
      </>
    );

    const enMarkup = (
      <>
        <ul>
          <li>Technical/financial/economic/environmental analysis and studies for selecting the optimal energy solution</li>
          <li>Technical-financial assessment of the current state of energy production and proposing optimal solutions for its effectiveness</li>
          <li>Preparation of documents needed for partial refund of expenses via the VIAP scheme (when providing public interest matching services)</li>
          <li>Preparation of documents for project financing via EU funds, the Climate Change Program or other funds (investment projects and feasibility studies, Cost-to-Benefit analysis, etc.)</li>
          <li>Preparation of tender documents, technical specifications, technical and other required expert advisories</li>
          <li>Construction management and technical supervision of projects</li>
          <li>Consultations on energy production project management: Preparation of special documents and permits and representation of client interests</li>
          <li>Legal consultations for energy production companies</li>
          <li>Investment negotiations and representation at the National Energy Regulatory Council</li>
        </ul>
      </>
    );

    this.setState({
      text: language == "lt" ? ltMarkup : enMarkup,
      closeBtnText: language == "lt" ? "Uždaryti" : "Close"
    });
  }


  expandCircle(colour) {
    const newIndex = this.state.circleIndex + 1;

    this.setState({
      circleIndex: newIndex
    });

    const svg = document.querySelector(".production_svg_circles");
    const svgNs = "http://www.w3.org/2000/svg";
    const circle = document.createElementNS(svgNs, "circle");
    circle.setAttributeNS(null, "cx", colour == "red" ? "40.54" : "760.4");
    circle.setAttributeNS(null, "cy", colour == "red" ? "249.6" : "249.73");
    circle.setAttributeNS(null, "r", "1");
    circle.setAttributeNS(null, "id", "circle" + newIndex);
    circle.setAttributeNS(null, "class", colour == "red" ? "cls-4 production_svg_circle-red" : "cls-4 production_svg_circle-grey");
    svg.appendChild(circle);

    const timeline = new TimelineMax();

    timeline.to("#circle" + newIndex, 5, {
      attr: {
        r: 3000
      }
    });
  }


  updateContainerHeight() {
    const container = document.querySelector(".service_ctr");

    const body = document.body;
    const html = document.documentElement;

    // const height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

    const height = document.body.clientHeight;
    container.style.height = height;
  }


  componentWillUnmount() {
    window.removeEventListener("resize", this.updateContainerHeight);
  }

  render() {
    return (
      <div className="service_ctr production_ctr">
        <div className="service_ctr_content production_ctr_content">
          <div className="service_ctr_close">
            <Link to="/">
              <button type="button" className="_mod_black _mod_transparent">{this.state.closeBtnText} X</button>
            </Link>
          </div>
          <div className="service_ctr_animation">
            <svg viewBox="0 0 800 500">
    <defs>
        <linearGradient id="linear-gradient" x1="248.15" x2="391.61" y1="254.99" y2="401.64" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#eeebe9" />
            <stop offset="0.26" stopColor="#989398" />
            <stop offset="0.52" stopColor="#f8f8f8" />
            <stop offset="0.88" stopColor="#979295" />
            <stop offset="1" stopColor="#dadada" />
            <stop offset="1" stopColor="#eeebe9" />
        </linearGradient>
        <linearGradient id="linear-gradient-2" x1="554.57" x2="394.36" y1="270.3" y2="407.12" xlinkHref="#linear-gradient" />
        <linearGradient id="linear-gradient-3" x1="556.6" x2="419.51" y1="249.09" y2="97.12" xlinkHref="#linear-gradient" />
        <linearGradient id="linear-gradient-4" x1="253.92" x2="400.93" y1="226.24" y2="93.4" xlinkHref="#linear-gradient" />
        <linearGradient id="linear-gradient-5" x1="403.21" x2="295.08" y1="331.68" y2="239.75" xlinkHref="#linear-gradient" />
        <linearGradient id="linear-gradient-6" x1="405.69" x2="499.47" y1="336.7" y2="235.48" xlinkHref="#linear-gradient" />
        <linearGradient id="linear-gradient-7" x1="395.88" x2="503.21" y1="154.84" y2="264.83" xlinkHref="#linear-gradient" />
        <linearGradient id="linear-gradient-8" x1="403.88" x2="297.61" y1="163" y2="259.18" xlinkHref="#linear-gradient" />

        <filter id="noise">
        <feTurbulence type="fractalNoise" baseFrequency="10" result="noisy" />
        <feColorMatrix type="saturate" values="0"/>
        <feBlend in="SourceGraphic" in2="noisy" mode="normal" />
      </filter>
    </defs>

              <g className="production_svg_circles">
              </g>


    <g className="_ele_clickable" onClick={this.expandCircle.bind(this, "red")}>
      <rect x="15" y="225" width="50" height="50" style={{ fill: "transparent" }} />
    <path className="production_svg_plus" d="M29.2,247.47h9.5V238h3.9v9.5h9.5v3.9H42.6v9.5H38.7v-9.5H29.2Z" />
    </g>


              <g className="_ele_clickable" onClick={this.expandCircle.bind(this, "grey")}>
                <rect x="735" y="225" width="50" height="50" style={{ fill: "transparent" }} />
    <path className="production_svg_minus" d="M749,247.47h22.4v3.9H749Z" />
              </g>

<g className="production_svg_text" style={{ zIndex: 100 }}>
    <text className="cls-1" transform="matrix(-0.78, -0.63, 0.63, -0.78, 255.53, 450.53)">
      {this.props.language == "lt" ? <>E</> : <>E</>}
    </text>
    <text className="cls-1" transform="matrix(-0.7, -0.71, 0.71, -0.7, 233.77, 432.87)">
      {this.props.language == "lt" ? <>N</> : <>N</>}
    </text>
    <text className="cls-1" transform="translate(214.11 412.87) rotate(-127.97)">
        {this.props.language == "lt" ? <>E</> : <>E</>}
    </text>
    <text className="cls-1" transform="matrix(-0.52, -0.85, 0.85, -0.52, 196.81, 390.78)">
        {this.props.language == "lt" ? <>R</> : <>R</>}
    </text>
    <text className="cls-1" transform="translate(182.11 366.91) rotate(-115.03)">
        {this.props.language == "lt" ? <>G</> : <>G</>}
    </text>
    <text className="cls-1" transform="matrix(-0.32, -0.95, 0.95, -0.32, 170.2, 341.52)">
        {this.props.language == "lt" ? <>I</> : <>Y</>}
    </text>
    <text className="cls-1" transform="translate(161.25 314.97) rotate(-102)">
        {this.props.language == "lt" ? <>J</> : <></>}
    </text>
    <text className="cls-1" transform="translate(155.37 287.54) rotate(-95.52)">
        {this.props.language == "lt" ? <>O</> : <>P</>}
    </text>
    <text className="cls-1" transform="translate(152.61 259.68) rotate(-89.07)">
        {this.props.language == "lt" ? <>S</> : <>R</>}
    </text>
    <text className="cls-1" transform="matrix(0.13, -0.99, 0.99, 0.13, 153, 231.6)">
      {this.props.language == "lt" ? <></> : <>O</>}
    </text>
    <text className="cls-1" transform="matrix(0.24, -0.97, 0.97, 0.24, 156.5, 203.79)">
        {this.props.language == "lt" ? <>G</> : <>D</>}
    </text>
    <text className="cls-1" transform="translate(163.13 176.55) rotate(-69.7)">
        {this.props.language == "lt" ? <>A</> : <>U</>}
    </text>
    <text className="cls-1" transform="translate(172.81 150.24) rotate(-63.19)">
        {this.props.language == "lt" ? <>M</> : <>C</>}
    </text>
    <text className="cls-1" transform="matrix(0.55, -0.84, 0.84, 0.55, 185.42, 125.18)">
        {this.props.language == "lt" ? <>Y</> : <>T</>}
    </text>
    <text className="cls-1" transform="matrix(0.64, -0.77, 0.77, 0.64, 200.76, 101.72)">
        {this.props.language == "lt" ? <>B</> : <>I</>}
    </text>
    <text className="cls-1" transform="matrix(0.72, -0.69, 0.69, 0.72, 218.65, 80.12)">
        {this.props.language == "lt" ? <>A</> : <>O</>}
    </text>
    <text className="cls-1" transform="translate(238.96 60.7) rotate(-37.86)">
      {this.props.language == "lt" ? <></> : <>N</>}
    </text>
    <text className="cls-3" transform="matrix(0.84, -0.54, 0.54, 0.84, 258.07, 45.95)">
      {this.props.language == "lt" ? <></> : <></>}
    </text>
    <text className="cls-3" transform="matrix(0.88, -0.48, 0.48, 0.88, 274.24, 35.46)">
      {this.props.language == "lt" ? <></> : <></>}
    </text>
    </g>

    <g className="production_svg_ring-outer">
    <path d="M389.91,397.1C308.13,391,246.55,319.53,252.63,237.75L282.55,240A118.86,118.86,0,0,0,392.14,367.18Z" className="cls-5" />
    <path d="M401.05,397.51c-3.69,0-7.4-.13-11.14-.41l2.23-29.92A118.88,118.88,0,0,0,519.35,257.59l29.92,2.23C543.46,337.86,478.09,397.51,401.05,397.51Z" className="cls-6" />
    <path d="M549.27,259.82l-29.92-2.23A118.73,118.73,0,0,0,409.76,130.38L412,100.47A148.72,148.72,0,0,1,549.27,259.82Z" className="cls-7" />
    <path d="M282.55,240l-29.92-2.22C258.72,156,330.2,94.37,412,100.47l-2.22,29.91A118.87,118.87,0,0,0,282.55,240Z" className="cls-8" />
    </g>
              <g className="production_svg_ring-inner">
    <path d="M401.44,335.9a86.73,86.73,0,0,1-86.63-86.63h30a56.69,56.69,0,0,0,56.63,56.63Z" className="cls-9" />
    <path d="M401.44,335.9v-30a56.7,56.7,0,0,0,56.63-56.63h30A86.73,86.73,0,0,1,401.44,335.9Z" className="cls-10" />
    <path d="M488.07,249.27h-30a56.71,56.71,0,0,0-59.63-56.55l-1.55-30c1.52-.08,3-.12,4.55-.12A86.73,86.73,0,0,1,488.07,249.27Z" className="cls-11" />
    <path d="M344.81,249.27h-30a86.57,86.57,0,0,1,82.08-86.51l1.55,30A56.58,56.58,0,0,0,344.81,249.27Z" className="cls-12" />
              </g>
</svg>

          </div>

          <div className="service_ctr_text production_ctr_description">
            <div>{this.state.text}</div>
          </div>
        </div>

        <LanguageSwitch />
      </div>
    );
  }
}

const Production = connect(mapStateToProps, null)(VisibleProduction);
export default Production;

import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { TimelineMax, TweenMax, Linear, Bounce, Elastic } from "gsap";


function mapStateToProps(state) {
  return {
    language: state.language,
    distributionDisplay: state.distributionDisplay
  };
}


export class VisibleDistribution extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: <></>,
      closeBtnText: "",
      showLtGradient: false,
      showRtGradient: false
    };

    this.showLeftGradient = this.showLeftGradient.bind(this);
    this.showRightGradient = this.showRightGradient.bind(this);
  }


  componentDidMount() {
    window.scroll(0, 0);
    this.setTextByLanguage(this.props.language);

    const topTextFragment = document.querySelector(".topTextFragment").cloneNode(true);
    const topText = document.querySelector("#topText");
    topText.appendChild(topTextFragment);
    const middleTextFragment = document.querySelector(".middleTextFragment").cloneNode(true);
    const middleText = document.querySelector("#middleText");
    middleText.appendChild(middleTextFragment);
    const bottomTextFragment = document.querySelector(".bottomTextFragment").cloneNode(true);
    const bottomText = document.querySelector("#bottomText");
    bottomText.appendChild(bottomTextFragment);

    TweenMax.to("#topText", 15, {
      x: -(topTextFragment.textLength.baseVal.value + 20),
      repeat: -1,
      ease: Linear.easeNone
    });
    TweenMax.to("#middleText", 15, {
      x: middleTextFragment.textLength.baseVal.value + 20,
      repeat: -1,
      ease: Linear.easeNone
    });
    TweenMax.to("#bottomText", 15, {
      x: -(bottomTextFragment.textLength.baseVal.value + 20),
      repeat: -1,
      ease: Linear.easeNone
    });

    this.startArrowAnims();
  }


  startArrowAnims() {
    TweenMax.to(".distribution_svg_arrow-lt", 0.5, {
      x: -30,
      repeat: -1,
      repeatDelay: 1.5,
      ease: Elastic.easeOut
    });

    TweenMax.to(".distribution_svg_arrow-rt", 0.5, {
      x: 30,
      delay: 1.5,
      repeat: -1,
      repeatDelay: 1.5,
      ease: Elastic.easeOut
    });
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.language != prevProps.language) {
      this.setTextByLanguage(this.props.language);
    }
  }


  setTextByLanguage(language) {
    const ltMarkup = (
      <>
        <ul>
          <li>CŠT sistemos hidraulinis modeliavimas</li>
          <li>CŠT sistemos planavimas, rekonstruojamų ruožų maršrutų ir skersmenų optimizavimas</li>
          <li>Kaštų naudos analizės, susijusios su CŠT paslaugos plėtros vertinimu</li>
          <li>Finansinės analizės, paraiškos finansinei paramai gauti ir kt. susijusios paslaugos</li>
          <li>Pirkimų dokumentų, techninių specifikacijų rastiems sprendimams parengimas, pirkimų priežiūra, ekspertinės pasiūlymų vertinimo paslaugos</li>
          <li>Kitos ekspertinės paslaugos, susijusios su užsakovo duomenų skaitmeninimu, kokybės gerinimu</li>
        </ul>
      </>
    );

    const enMarkup = (
      <>
        <ul>
          <li>Hydraulic modelling of the District Heating Network (DHN)</li>
          <li>Territorial planning of DHN and the optimisation of reconstructed routes and diameters</li>
          <li>Cost benefit analysis for the potential development of DHN projects</li>
          <li>Financial analysis and all procedures needed for project funding</li>
          <li>Consultations on energy distribution project management: Preparation of special documents and permits and the representation of client interests</li>
          <li>Other expert consultancy services</li>
        </ul>
      </>
    );

    this.setState({
      text: language == "lt" ? ltMarkup : enMarkup,
      closeBtnText: language == "lt" ? "Uždaryti" : "Close"
    });
  }


  showLeftGradient() {
    const timeline = new TimelineMax();


      timeline.to(".service_ctr_gradient", 0.25, {
        backgroundImage: "linear-gradient(to right, transparent, transparent)"
      });


    timeline.to(".service_ctr_gradient", 0.5, {
      backgroundImage: "linear-gradient(to right, #f03, transparent)"
    });

    timeline.to(".service_ctr_gradient", 0.5, {
      backgroundImage: "linear-gradient(to right, #f03, #f03)"
    });

    timeline.play();

    this.setState({
      showLtGradient: true,
      showRtGradient: false
    });
  }


  showRightGradient() {
    const timeline = new TimelineMax();


      timeline.to(".service_ctr_gradient", 0.25, {
        backgroundImage: "linear-gradient(to right, transparent, transparent)"
      });


    timeline.to(".service_ctr_gradient", 0.5, {
      backgroundImage: "linear-gradient(to right, transparent, #f03)"
    });

    timeline.to(".service_ctr_gradient", 0.5, {
      backgroundImage: "linear-gradient(to right, #f03, #f03)"
    });

    timeline.play();

    this.setState({
      showLtGradient: false,
      showRtGradient: true
    });
  }


  showGradient() {

  }


  render() {
    let svgText1;
    let svgText2;

    if(this.props.language == "lt") {
      svgText1 = "ENERGIJOS TIEKIMAS";
      svgText2 = "TIEKIMAS ENERGIJOS";
    } else {
      svgText1 = "ENERGY PRODUCTION";
      svgText2 = "PRODUCTION ENERGY";
    }

    return (
      <div className="service_ctr" style={{ left: this.props.distributionDisplay == true ? 0 : "100vw" }}>
        <div className="service_ctr_content">
          <div className="service_ctr_close">
            <Link to="/">
              <button type="button" className="_mod_black _mod_transparent">{this.state.closeBtnText} X</button>
            </Link>
          </div>
          <div className="service_ctr_animation">
            <svg x="0px" y="0px" viewBox="0 0 800 500">
              <g>
                <g>
                  <linearGradient id="animate-gradient-non-symmetry-left" x1="36" y1="262.0553" x2="332.9669" y2="262.0553" spreadMethod="reflect">
                    <stop offset="0" stopColor="#fff"></stop>
                    <stop offset="0.2" stopColor="#f4f4f4"></stop>
                    <stop offset="0.5" stopColor="#8c878a"></stop>
                    <stop offset="0.8" stopColor="#f4f4f4"></stop>
                    <stop offset="1" stopColor="#fff"></stop>
                    <animate attributeName="x1" values="200%;0%" dur="4s" repeatCount="indefinite"></animate>
                    <animate attributeName="x2" values="300%;100%" dur="4s" repeatCount="indefinite"></animate>
                  </linearGradient>
                  <rect width="297" height="35.1" x="250.94" y="121.02" className="cls-1" style={{ fill: "url('#animate-gradient-non-symmetry-left')" }} />
                </g>

                <g>
                  <clipPath id="topTextClip">
                    <rect x="250.94" y="185" width="297" height="40" style={{ fill: "red" }} />
                  </clipPath>

                  <clipPath id="middleTextClip">
                    <rect x="250.94" y="225" width="297" height="40" style={{ fill: "red" }} />
                  </clipPath>

                  <clipPath id="bottomTextClip">
                    <rect x="250.94" y="265" width="297" height="40" style={{ fill: "red" }} />
                  </clipPath>

                  <g clipPath="url(#topTextClip)">
                    <text id="topText" x="250.94" y="220" style={{ fontSize: "46" }}>
                      <tspan className="topTextFragment">{svgText1} </tspan>
                    </text>
                  </g>

                  <g clipPath="url(#middleTextClip)">
                    <text id="middleText" x="-258" y="260" style={{ fontSize: "46" }}>
                      <tspan className="middleTextFragment">{svgText2} </tspan>
                    </text>
                  </g>

                  <g clipPath="url(#bottomTextClip)">
                    <text id="bottomText" x="250.94" y="300" style={{ fontSize: "46" }}>
                      <tspan className="bottomTextFragment">{svgText1} </tspan>
                    </text>
                  </g>
                </g>

                <g>
                  <linearGradient id="animate-gradient-non-symmetry-right" x1="-4022.0198" y1="42.0945" x2="-3725.0527" y2="42.0945" spreadMethod="reflect">
                    <stop offset="0" stopColor="#fff"></stop>
                    <stop offset="0.2" stopColor="#f4f4f4"></stop>
                    <stop offset="0.5" stopColor="#8c878a"></stop>
                    <stop offset="0.8" stopColor="#f4f4f4"></stop>
                    <stop offset="1" stopColor="#fff"></stop>
                    <animate attributeName="x1" values="0%;200%" dur="4s" repeatCount="indefinite"></animate>
                    <animate attributeName="x2" values="100%;300%" dur="4s" repeatCount="indefinite"></animate>
                  </linearGradient>
                  <rect width="297" height="35.1" x="250.94" y="341.02" className="cls-2" style={{ fill: "url('#animate-gradient-non-symmetry-right')" }} />

                </g>

                <g className="distribution_svg_arrow-lt" onClick={this.showRightGradient} style={{ cursor: "pointer" }}>
                  <rect x="20" y="115" width="40" height="40" style={{ fill: "transparent" }} />
                  <polygon points="51.11 132.82 30.61 132.82 39.81 124.22 37.81 122.02 24.61 134.42 37.81 146.62 39.81 144.42 30.61 135.92 51.11 135.92 51.11 132.82"/>
                </g>

                <g className="distribution_svg_arrow-rt" onClick={this.showLeftGradient} style={{ cursor: "pointer" }}>
                  <rect x="740" y="340" width="40" height="40" style={{ fill: "transparent" }} />
                  <polygon points="748.52 360.32 769.02 360.32 759.72 351.82 761.72 349.62 775.02 361.92 761.72 374.12 759.72 371.92 769.02 363.32 748.52 363.32 748.52 360.32"/>
                </g>
              </g>
            </svg>
          </div>

          <div className="service_ctr_text">
            <div>{this.state.text}</div>
          </div>
        </div>

        <div className="service_ctr_gradient"></div>
      </div>
    );
  }
}

const Distribution = connect(mapStateToProps, null)(VisibleDistribution);
export default Distribution;

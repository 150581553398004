import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

import { togglePrivacy } from "./state/actions";


function mapStateToProps(state) {
  return {
    language: state.language
  };
}


function mapDispatchToProps(dispatch) {
  return {
    togglePrivacy: flag => dispatch(togglePrivacy(flag))
  }
}


export class VisibleContact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      markup: (
        <></>
      ),
      privacyLinkText: "",
      copyrightYears: "2013"
    };

    this.showPrivacy = this.showPrivacy.bind(this);
  }


  componentDidMount() {
    this.setContentByLanguage(this.props.language);
    this.setCopyrightYears();
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.language != prevProps.language) {
      this.setContentByLanguage(this.props.language);
    }
  }


  showPrivacy(event) {
    event.preventDefault();

    this.props.togglePrivacy(true);
  }


  setContentByLanguage(language) {
    const socialMarkup = (
      <div className="contact_ctr_social">
        <a href="https://www.linkedin.com/company/uab-teisingi-energetikos-sprendimai" title="LinkedIn" target="_blank" rel="noopener noreferrer">
          <img src="assets/linkedin-icon.svg" alt="LinkedIn" />
        </a>
      </div>
    );


    const ltMarkup = (
      <>
        <ScrollAnimation animateIn="fadeInUp">
          <p>
            UAB „TEISINGI ENERGETIKOS SPRENDIMAI”<br />
            ĮM. KODAS 303053559<br />
            PVM M. KODAS LT100007722915<br />
            A/S LT30 7044 0600 0789 4776<br />
            AB „SEB BANKAS”
          </p>

          <p>
            LAISVĖS AL. 5-2<br />
            LT-44237, KAUNAS, LIETUVA
          </p>

          <p>
            DIREKTORIUS – ROBERTAS PUODŽIUS<br />
            TEL. +370 612 99992<br />
            EL.P. <a href="mailto:robertas@tes.lt" title="">ROBERTAS@TES.LT</a>
          </p>

          {socialMarkup}
        </ScrollAnimation>
      </>
    );

    const enMarkup = (
      <>
        <ScrollAnimation animateIn="fadeInUp">
          <p>
            UAB „Teisingi energetikos sprendimai”<br />
            Company code: 303053559<br />
            VAT identifier: LT100007722915<br />
            Bank account: LT30 7044 0600 0789 4776<br />
            Bank name: AB „SEB Bankas”
          </p>

          <p>
            Laisvės Al. 5-2<br />
            LT-44237, Kaunas, Lithuania
          </p>

          <p>
            Director Robertas Puodžius<br />
            Phone: +370 612 99992<br />
            E-mail: <a href="mailto:robertas@tes.lt" title="">robertas@tes.lt</a>
          </p>

          {socialMarkup}
        </ScrollAnimation>
      </>
    );

    this.setState({
      markup: language == "lt" ? ltMarkup : enMarkup,
      privacyLinkText: language == "lt" ? "Privatumo Politika" : "Privacy Policy"
    });
  }


  setCopyrightYears() {
    const now = new Date().getFullYear();
    console.log(now);
    this.setState({
      copyrightYears: now == "2013" ? "2013" : "2013-" + now
    });
  }


  render() {
    return (
      <div className="contact_ctr" data-midnight="white">
        <div className="contact_ctr_content">
          <div className="contact_lnk_email">
            <a href="mailto:info@tes.lt" title="">info@tes.lt</a>
          </div>

          <div className="contact_txt_info">
            {this.state.markup}
          </div>
        </div>

        <div className="contact_lnk_privacy">
          <Link to="/privacy">{this.state.privacyLinkText}</Link>
        </div>

        <div className="contact_ctr_copyright"><span>&copy;</span> {this.state.copyrightYears} TES</div>
      </div>
    );
  }
}

const Contact = connect(mapStateToProps, mapDispatchToProps)(VisibleContact);
export default Contact;

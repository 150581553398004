import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import { TimelineMax, TweenMax, Linear, Bounce } from "gsap";
import { Draggable } from "gsap/Draggable";
import { ThrowPropsPlugin } from "./ThrowPropsPlugin";
import { DrawSVGPlugin } from "./DrawSVGPlugin";


function mapStateToProps(state) {
  return {
    language: state.language,
    consumptionDisplay: state.consumptionDisplay
  };
}


export class VisibleConsumption extends Component {
  dial;
  rotationTimeline;

  constructor(props) {
    super(props);

    this.state = {
      text: <></>,
      closeBtnText: "",
      progress: 0,
      animTitle: <></>
    };

    this.setMin = this.setMin.bind(this);
    this.setMax = this.setMax.bind(this);
    this.updateAnimation = this.updateAnimation.bind(this);
  }


  componentDidMount() {
    window.scroll(0, 0);
    this.setTextByLanguage(this.props.language);
    this.setAnimatedTitleByLanguage(this.props.language);

    this.rotationTimeline = new TimelineMax({
      repeat: -1
    });

    this.rotationTimeline.to(".consumption_svg_ring", 10, {
      css: {
        transformOrigin: "center",
        rotation: 360
      },
      ease: Linear.easeNone
    });

    TweenMax.set(".consumption_svg_dial", {
      transformOrigin: "center"
    });

    this.dial = Draggable.create(".consumption_svg_dial", {
      type: "rotation",
      throwProps: true,
      bounds: {
        minRotation: 0,
        maxRotation: 270
      },
      onDrag: () => {
        this.updateAnimation();
      }
    })

    TweenMax.to(".consumption_svg_progress", 0, {
      drawSVG: "0%"
    });
  }


  animateLabel(label) {
    const timeline = new TimelineMax();
    const selector = label == "min" ? ".consumption_svg_label-min" : ".consumption_svg_label-max";

    timeline.to(selector, 0.5, {
      scale: 1.5,
      transformOrigin: "50% 50%",
      ease: Bounce.easeOut
    })
      .to(selector, 0.5, {
        scale: 1,
        transformOrigin: "50% 50%",
        ease: Linear.easeOut
    });
  }


  startLabelAnims() {
    TweenMax.to(".consumption_svg_label-min", 2, {
      scaleX: 0.5,
      scaleY: 0.5,
      transformOrigin: "50% 50%",
      repeat: -1,
      ease: Bounce.easeOut
    });

    TweenMax.to(".consumption_svg_label-max", 2, {
      scaleX: 1.5,
      scaleY: 1.5,
      transformOrigin: "50% 50%",
      delay: 1.5,
      repeat: -1,
      ease: Bounce.easeOut
    });
  }


  updateAnimation() {
    const normal = this.dial[0].rotation;
    const proportion = normal / 270;

    TweenMax.to(".consumption_svg_progress", 0.5, {
      drawSVG: (proportion * 100) + '%'
    });

    const rotationDuration = 10 - ((1 - proportion) * 9);

    this.rotationTimeline.timeScale(rotationDuration);

    TweenMax.to(".consumption_ctr_backing", 0.1, {
      css: {
        backgroundColor: "rgba(255, 0, 51, " + proportion + ")"
      },
      ease: Linear.easeNone
    });
  }


  setMin() {
    this.animateLabel("min");

    TweenMax.to(".consumption_svg_dial", 0.5, {
      rotation: 0,
      onComplete: () => {
        TweenMax.set(this.dial, {
          rotation: 0
        });
        this.updateAnimation();
      }
    });
  }


  setMax() {
    this.animateLabel("max");

    TweenMax.to(".consumption_svg_dial", 0.5, {
      rotation: 270,
      onComplete: () => {
        TweenMax.set(this.dial, {
          rotation: 270
        });
        this.updateAnimation();
      }
    })
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.language != prevProps.language) {
      this.setTextByLanguage(this.props.language);
    }


    const animTextTimeline = new TimelineMax({
      repeat: -1
    });

    animTextTimeline.from(".consumption_svg_anim-text", 2, {
        y: 80,
        ease: Bounce.easeOut
      })
      .to(".consumption_svg_anim-text", 2, {
        y: 0,
        ease: Bounce.easeOut
      })
      .to(".consumption_svg_anim-text", 1, {
        y: -100,
        ease: Bounce.easeOut,
        delay: 2
      });
  }


  setTextByLanguage(language) {
    const ltMarkup = (
      <>
        <ul>
          <li>Energijos vartojimo auditas</li>
          <li>Kitos su energijos suvartojimu susijusios konsultacijos</li>
        </ul>
      </>
    );

    const enMarkup = (
      <>
        <ul>
          <li>Energy audits</li>
          <li>Other consultations in energy consumption efficiency</li>
        </ul>
      </>
    );

    this.setState({
      text: language == "lt" ? ltMarkup : enMarkup,
      closeBtnText: language == "lt" ? "Uždaryti" : "Close"
    });
  }


  setAnimatedTitleByLanguage(language) {
    const ltSvg = (
      <>
        <svg viewBox="0 0 640 80">
    <defs>

    </defs>
    <text className="cls-1" transform="translate(6.44 58.42)">
        ENERGIJOS VARTOJIMA
    </text>

              <clipPath id="clip">
                <rect width="63.56" height="76.52" x="571.69" y="1.77" rx="12.67" />
              </clipPath>
              <g clipPath="url(#clip)">
                <rect className="consumption_svg_anim-text-bg" width="63.56" height="76.52" x="571.69" y="1.77" rx="12.67" />
                <g className="consumption_svg_anim-text">
    <text className="cls-1" transform="translate(587.53 56.83)">
        S
    </text></g>
              </g>
    <rect width="63.56" height="76.52" x="571.69" y="1.77" className="cls-2" rx="12.67" />
</svg>
      </>
    );

    const enSvg = (
      <svg viewBox="0 0 590 80">
    <defs>

    </defs>
    <text className="cls-1" transform="translate(7.2 56.64)">
        ENERGY CONSUMPTIO
    </text>
        <clipPath id="clip">
          <rect width="63.56" height="76.52" x="514.23" y="2.38" rx="12.67" />
        </clipPath>
        <g clipPath="url(#clip)">
          <rect className="consumption_svg_anim-text-bg" width="63.56" height="76.52" x="514.23" y="2.38" rx="12.67" />
          <g className="consumption_svg_anim-text">
    <text className="cls-1" transform="translate(530.07 57.43)">
        N
    </text>
          </g>
        </g>
    <rect width="63.56" height="76.52" x="514.23" y="2.38" className="cls-2" rx="12.67" />
</svg>
    );

    this.setState({
      animTitle: language == "lt" ? ltSvg : enSvg
    });
  }


  render() {
    return (
      <div className="service_ctr consumption_ctr" style={{ left: this.props.consumptionDisplay == true ? 0 : "100vw" }}>
        <div className="consumption_ctr_backing"></div>
        <div className="service_ctr_content">
          <div className="service_ctr_close">
            <Link to="/">
              <button type="button" className="_mod_black _mod_transparent">{this.state.closeBtnText} X</button>
            </Link>
          </div>
          <div className="service_ctr_animation consumption_ctr_animation">
            <svg viewBox="0 0 800 500">
    <defs>
        <linearGradient id="linear-gradient" x1="249.61" x2="393.08" y1="254.37" y2="401.02" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#eeebe9" />
            <stop offset="0.26" stopColor="#989398" />
            <stop offset="0.52" stopColor="#f8f8f8" />
            <stop offset="0.88" stopColor="#979295" />
            <stop offset="1" stopColor="#dadada" />
            <stop offset="1" stopColor="#eeebe9" />
        </linearGradient>
        <linearGradient id="linear-gradient-2" x1="556.03" x2="395.82" y1="269.68" y2="406.5" xlinkHref="#linear-gradient" />
        <linearGradient id="linear-gradient-3" x1="558.06" x2="420.97" y1="248.47" y2="96.5" xlinkHref="#linear-gradient" />
        <linearGradient id="linear-gradient-4" x1="255.39" x2="402.4" y1="225.62" y2="92.78" xlinkHref="#linear-gradient" />
    </defs>

              <g className="consumption_svg_label-min" onClick={this.setMin}>
                <rect x="205" y="370" width="80" height="40" style={{ fill: "transparent" }} />
    <path d="M218.36,380h4l2.4,16.6h.3l2.4-16.6h4v17.5h-1.9v-16.6h-.3l-2.4,16.6H223l-2.4-16.6h-.2v16.6h-2Z" />
    <path d="M239.66,380h11.1v2h-4.5v13.6h4.5v2h-11.1v-2h4.5V382h-4.5Z" />
    <path d="M269,396.56h.3V380h2.1v17.5h-4.2l-5-16.6h-.3v16.6h-2.1V380H264Z" />
              </g>

              <g className="consumption_svg_label-max" onClick={this.setMax}>
                <rect x="520" y="370" width="80" height="40" style={{ fill: "transparent" }} />
    <path d="M534.06,380h4l2.4,16.6h.3l2.4-16.6h4v17.5h-1.9v-16.6H545l-2.4,16.6h-3.8l-2.4-16.6h-.3v16.6h-2V380Z" />
    <path d="M564.66,393.06h-7.5l-1.3,4.3h-2.2l5.3-17.5h4l5.3,17.5H566Zm-3.9-12.2-3,10.3h6.4l-3-10.3Z" />
    <path d="M577.06,380l3.9,7.8h.5l3.9-7.8h2.3l-4.4,8.6v.3l4.4,8.6h-2.3l-3.9-7.8H581l-3.9,7.8h-2.3l4.4-8.6v-.3l-4.4-8.6Z" />
              </g>

    <path d="M239.15,354.12c-48.24-75.7-38.16-177.36,29-242,76.6-73.7,198.44-71.35,272.14,5.26,62.07,64.51,70.2,161.11,25.47,234" className="cls-1 consumption_svg_progress" />

    <g className="consumption_svg_dial">
      <path d="M362.2,272.1c5.7-0.1,11.2,2.2,15.2,6.3c8.3,8.6,8,22.2-0.5,30.5c-8.4,8.1-21.6,8.1-30,0l0,0c-8.4-8.4-8.4-22.1,0-30.5
	C351,274.4,356.5,272.1,362.2,272.1z"/>
      <circle cx="403.25" cy="248.39" r="60.38" className="cls-2" />
      <g className="consumption_svg_ring">
        <path d="M391.38,396.48C309.6,390.39,248,318.91,254.1,237.13L284,239.35A118.87,118.87,0,0,0,393.6,366.56Z" className="cls-3" />
        <path d="M402.52,396.89c-3.69,0-7.4-.13-11.14-.41l2.22-29.92A118.88,118.88,0,0,0,520.81,257l29.92,2.23C544.92,337.24,479.55,396.89,402.52,396.89Z" className="cls-4" />
        <path d="M550.73,259.2,520.81,257A118.73,118.73,0,0,0,411.22,129.76l2.23-29.91A148.72,148.72,0,0,1,550.73,259.2Z" className="cls-5" />
        <path d="M284,239.35l-29.91-2.22c6.08-81.79,77.56-143.38,159.35-137.28l-2.23,29.91A118.87,118.87,0,0,0,284,239.35Z" className="cls-6" />
      </g>
      <ellipse className="st5" cx="402.8" cy="248.2" rx="118.8" ry="119.1" style={{ fill: "transparent" }} />
    </g>
</svg>
          </div>

          <div className="consumption_ctr_animated-title">
            {this.state.animTitle}
          </div>

          <div className="service_ctr_text">
            <div>{this.state.text}</div>
          </div>
        </div>
      </div>
    );
  }
}

const Consumption = connect(mapStateToProps, null)(VisibleConsumption);
export default Consumption;

import * as Actions from "./actions";


const initialState = {
  language: window.localStorage.getItem("be_tes_lang") || "lt",
  pageScrollPos: 0,
  servicesBgRed: false,
  privacyDisplay: false,
  fixHome: false,
  windowWidth: 0,
  stopServiceIconAnimsProp: false
};


export function rootReducer(state = initialState, action) {
  switch(action.type) {
  case Actions.SET_LANGUAGE:
    return Object.assign({}, state, {
      language: action.payload
    });
  case Actions.UPDATE_PAGE_SCROLL_POS:
    return Object.assign({}, state, {
      pageScrollPos: action.payload
    });
  case Actions.TOGGLE_SERVICES_BG:
    return Object.assign({}, state, {
      servicesBgRed: action.payload
    });
  case Actions.TOGGLE_PRIVACY:
    return Object.assign({}, state, {
      privacyDisplay: action.payload,
      fixHome: action.payload
    });
  case Actions.FIX_HOME:
    return Object.assign({}, state, {
      fixHome: action.payload
    });
  case Actions.UPDATE_WIN_WIDTH:
    return Object.assign({}, state, {
      windowWidth: action.payload
    });
  case Actions.STOP_SERVICE_ICON_ANIMS:
    return Object.assign({}, state, {
      stopServiceIconAnimsProp: action.payload
    });
  default:
    return state;
  }
}

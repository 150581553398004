import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { togglePrivacy } from "./state/actions";


function mapStateToProps(state) {
  return {
    language: state.language,
    privacyDisplay: state.privacyDisplay
  };
}


function mapDispatchToProps(dispatch) {
  return {
    togglePrivacy: flag => dispatch(togglePrivacy(flag))
  };
}


export class VisiblePrivacy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: "",
      markup: (
        <></>
      ),
      closeBtnText: ""
    };

    this.closePrivacy = this.closePrivacy.bind(this);
  }


  componentDidMount() {
    window.scroll(0, 0);

    this.setContentByLanguage(this.props.language);
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.language != prevProps.language) {
      this.setContentByLanguage(this.props.language);
    }
  }


  closePrivacy() {
    this.props.togglePrivacy(false);
  }


  setContentByLanguage(language) {
    const ltMarkup = (
      <>
        <p>Ši svetainė skirta pristatyti konsultacinės bendrovės UAB „Teisingi energetikos sprendimai“ (TES) veiklą bei teikiamas konsultacines paslaugas. Mes nenaudojame šios erdvės rinkti kokią nors informaciją – nei asmenis identifikuojančių duomenų, nei anoniminių statistinių lankytojų parametrų per įdiegtus slapukus (<em>angl. cookies</em>) ar trečiųjų šalių (<em>pvz., Google Analytics</em>) įskiepius, IŠSKYRUS anoniminius duomenis, susijusius su svetainės naršymo kalbos versijų pasirinkimu: šie duomenys saugomi jūsų naudojamos naršyklės istorijoje, kuriuos galite bet kuriuo metu patys ištrinti.</p>

        <p>Jūsų el. pašto adresas, vardas bei pavardė, kontaktiniai duomenys (<em>jeigu juos nurodote mums siųsdami el. laišką</em>) naudojami ir tvarkomi tik siekiant tinkamai įvertinti ir atsakyti į Jūsų užklausą. Jie jokiais atvejais nėra naudojami rinkodaros tikslais ar perduodami trečiosioms šalims. Jūs patys galite pasirinkti, kokius duomenis apie save pageidaujate pateikti siųsdami mums el. laiškus. Patvirtiname, kad el. paštu Jūsų pačių mums siunčiami, taip pat mūsų socialinių tinklų paskyrų lankytojų duomenys renkami laikantis galiojančių Europos Sąjungos bei Lietuvos Respublikos teisės aktų reikalavimų bei kontroliuojančių institucijų nurodymų.</p>

        <p>Jūsų asmens duomenys, įgyvendinant mūsų teisėtą interesą kaupti įrodymus apie gautas užklausas el. paštu arba per socialinių medijų paskyras, atsakymus į jas, taip pat suteiktas paslaugas ir turėtą kontaktą, priklausomai nuo duomenų rūšies ir aplinkybių, saugomi tol, kol atitinka tikslus, kuriems buvo surinkti. Jūs turite teisę susipažinti su savo asmens duomenimis ir su tuo, kaip jie yra tvarkomi, taip pat galite reikalauti, kad mes ištrintume visus mūsų turimus Jūsų asmeninius duomenis. Jeigu turite kokių nors klausimų, susijusių su asmens duomenų apsauga ar kitais su privatumu susijusiais klausimais, prašome kreiptis el.p. <a className="_mod_underline" href="mailto:info@tes.lt" title="">info@tes.lt</a> arba tel. 8 612 99992.</p>
      </>
    );

    const enMarkup = (
      <>
        <p>The aim of our website is to promote the consulting services of Teisingi Energetikos Sprendimai (TES) by introducing our company’s mission and the services themselves. We do not intentionally collect any personal identifiable data about the visitors to our website. We do not register any users, allow comments on any content, provide any newsletter subscriptions, accept any submitted data via contact forms or permit the upload of files. We also do not collect any (<em>anonymous</em>) data generated from technical processes such as cookies, third-party (<em>e.g., Google Analytics</em>) embeds or other analytics services WITH THE EXCEPTION OF anonymous data relating to the language setting you select to view the website and the page scrolling position that allows us to deliver the dynamic experiences you get from using the website; these data are stored in your web browser's local storage mechanism and can be deleted following the relevant procedure as per your browser.</p>

        <p>We process your data in cases where you contact us by e-mail yourself, sending information or queries to contacts indicated on our website. In these cases we do not use this data for any marketing or other purposes other then to provide you with relevant information and to answer your requests. We seek to ensure that any (<em>technical</em>) personal data is processed accurately, fairly and lawfully and only for the purposes for which it was collected, in accordance with the principles and requirements of personal data processes as established in legal acts of the Republic of Lithuania and the EU.</p>

        <p>Your personal data, in pursuit of our legitimate interest in collecting evidence about requests received via e-mail, the replies to them, as well as the services provided and the contact we had, is retained, depending on the type of data and circumstances, as long as it is in line with the purposes for which it was collected. You can request information on what personal data we hold in relation to yourself and how it is processed. You can also request that we erase any personal data we hold about you.</p>

        <p>For any privacy specific concerns  please  do not hesitate to contact us by e-mail at <a className="_mod_underline" href="mailto:info@tes.lt" title="">info@tes.lt</a> or phone +370 612 99992.</p>
      </>
    );

    this.setState({
      title: language == "lt" ? "PRIVATUMO POLITIKA IR ASMENS DUOMENŲ APSAUGA" : "PRIVACY POLICY AND PERSONAL DATA PROTECTION",
      markup: language == "lt" ? ltMarkup : enMarkup,
      closeBtnText: language == "lt" ? "Uždaryti" : "Close"
    });
  }


  render() {
    return (
      <div className="privacy_ctr">
        <div className="privacy_ctr_content">
          <div className="privacy_ctr_close">
            <Link to="/">
            <button type="button" className="_mod_black">{this.state.closeBtnText} x</button>
            </Link>
          </div>

          <div className="privacy_ctr_text">
            <p className="privacy_txt_title">{this.state.title}</p>

            {this.state.markup}
          </div>
        </div>
      </div>
    );
  }
}

const Privacy = connect(mapStateToProps, mapDispatchToProps)(VisiblePrivacy);
export default Privacy;

export const SET_LANGUAGE = "SET_LANGUAGE";
export const UPDATE_PAGE_SCROLL_POS = "UPDATE_PAGE_SCROLL_POS";
export const TOGGLE_SERVICES_BG = "TOGGLE_SERVICES_BG";
export const TOGGLE_PRIVACY = "TOGGLE_PRIVACY";
export const FIX_HOME = "FIX_HOME";
export const UPDATE_WIN_WIDTH = "UPDATE_WIN_WIDTH";
export const STOP_SERVICE_ICON_ANIMS = "STOP_SERVICE_ICON_ANIMS";


export function setLanguage(payload) {
  return {
    type: SET_LANGUAGE,
    payload
  };
}


export function updatePageScrollPosition(payload) {
  return {
    type: UPDATE_PAGE_SCROLL_POS,
    payload
  };
}


export function toggleServicesBackgroundRed(payload) {
  return {
    type: TOGGLE_SERVICES_BG,
    payload
  };
}


export function togglePrivacy(payload) {
  return {
    type: TOGGLE_PRIVACY,
    payload
  };
}


export function fixHome(payload) {
  return {
    type: FIX_HOME,
    payload
  };
}


export function updateWindowWidth(payload) {
  return {
    type: UPDATE_WIN_WIDTH,
    payload
  };
}


export function stopServiceIconAnims(payload) {
  return {
    type: STOP_SERVICE_ICON_ANIMS,
    payload
  };
}

import React, { Component } from "react";


export class Title extends Component {
  render() {
    return (
      <div className="title_ctr">
        <svg x="0px" y="0px" viewBox="0 0 200 80">
          <g>
            <g>
              <g clipPath="url(#window)">
                <rect className="scroll" x="25" y="15" width="160" height="100" />
                  <text className="title_svg_about" x={this.props.language == "lt" ? 52 : 45} y="87">{this.props.language == "lt" ? "APIE TES" : "ABOUT TES"}</text>
                  <text className="title_svg_services" x={this.props.language == "lt" ? 45 : 50} y="87">{this.props.language == "lt" ? "PASLAUGOS" : "SERVICES"}</text>
                  <text className="title_svg_contact" x={this.props.language == "lt" ? 45 : 60} y="87">{this.props.language == "lt" ? "KONTAKTAI" : "CONTACT"}</text>
              </g>

              <clipPath id="window">
                <rect x="29.9" y="17.6" rx="10" ry="10" width="140" height="45" />
              </clipPath>

              <rect x="29.9" y="17.6" rx="10" ry="10" className="st0" width="140" height="45" fill="url(#shadow)" />
            </g>

            <g>
              <pattern id="shadow" patternUnits="userSpaceOnUse" x="0" y="0" width="169" height="69">
                <image width="169" height="69" href="assets/shadow.png" transform="matrix(1 0 0 1 17 9)"></image>
              </pattern>

              <g>
                <g>
                  <path className="st1" d="M24.9,15.1v50h150v-50H24.9z M164.1,55c0,1.9-1.9,3.5-4.1,3.5H39.8c-2.3,0-4.1-1.6-4.1-3.5V25.3c0-1.9,1.9-3.5,4.1-3.5H160c2.3,0,4.1,1.6,4.1,3.5V55z" />
                </g>
              </g>
            </g>
          </g>

          <path className="st1" d="M-0.1,0.1v80h200v-80H-0.1z M172.4,62.6h-145v-45h145V62.6z" />
        </svg>
      </div>
    );
  }
}
